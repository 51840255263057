<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button
                  v-shortkey.once="['ctrl', 's']"
                  v-if="editable"
                  class="btn btn-success mr-2"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>

                <button class="btn btn-primary mr-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="mr-4">
                <button
                  class="btn btn-danger"
                  @click="oeffneLoeschenModal"
                  v-if="berechtigungen.b_bildung_bildungsportfolio.delete"
                >
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <container-headline
          :headline="$t('global.masterdata')"
          :col="3"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <b-form-input
                          v-model.trim="subventionsbeitrag.bezeichnung"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.designation") }}</label>
                      </div>
                    </div>

                    <div class="col-xl-3">
                      <div class="form-group">
                        <b-form-input
                          v-model.number="subventionsbeitrag.betrag"
                          type="number"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.amount") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <v-select
                          v-model="subventionsbeitrag.subventionsbeitragstatus"
                          label="bezeichnung"
                          :options="beitragstati"
                          :placeholder="$t('global.subsidycontributionstatus')"
                          :clearable="true"
                          :readonly="!editable"
                        >
                        </v-select>
                        <label>{{
                          $t("global.subsidycontributionstatus")
                        }}</label>
                      </div>
                    </div>

                    <div class="col-xl-3">
                      <div class="form-group">
                        <v-select
                          v-model.trim="subventionsbeitrag.subventionskanton"
                          :options="subventionskantone"
                          :reduce="(s) => s.id"
                          label="bezeichnung"
                          :placeholder="$t('global.subsidycanton')"
                          :readonly="!editable"
                        >
                        </v-select>
                        <label>{{ $t("global.subsidycanton") }}</label>
                      </div>
                    </div>

                    <div class="col-xl-3">
                      <div class="form-group">
                        <v-select
                          v-model.trim="subventionsbeitrag.beitragsintervall"
                          :options="beitragsintervalle"
                          :reduce="(s) => s.id"
                          label="bezeichnung"
                          :placeholder="$t('global.subsidyinterval')"
                          :readonly="!editable"
                        >
                        </v-select>
                        <label>{{ $t("global.subsidyinterval") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <subventionsdaten
          :subvention="subvention"
          :editable="editable"
          @kantoneChanged="setKantone"
        />
      </div>

      <div v-if="subventionid" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 0 }"
                  :to="{
                    name: 'fach',
                    params: { subvention: subventionid, anzeige: 0 },
                  }"
                  >Subventionsbeiträge</router-link
                >
              </li>
            </ul>
          </div>

          <div class="col-xl-12 p-0">
            <div class="tab-container p-3">
              <div v-show="anzeige === 0">
                <Subventionsbeitraege
                  :subvention="subventionid"
                  :kantone="kantone"
                  :shown="anzeige == 0 ? true : false"
                  :editable="editable"
                  :key="beitragReiterKey"
                  @updateBeitraege="beitragReiterKey++"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>

    <loeschen-modal
      id="subvention-loeschen-modal"
      @confirmed="subventionsbeitragLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import ContainerHeadline from "@/components/ContainerHeadline";

import { LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG } from "@/store/subventionbeitrag/actions.type";
import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  name: "Subventionsbeitrag",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    ContainerHeadline,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    subventionsbeitragid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      subventionsbeitrag: {
        bezeichnung: "",
        betrag: 0,
        subventionskanton: { bezeichnung: "" },
        subventionsbeitragstatus: { bezeichnung: "" },
        beitragsintervall: { bezeichnung: "" },
      },
      /*
      subvention: {
        person: {},
        subventionskanton: {},
        subventionsstatus: {},
        ausbildungstatus: {},
        ausbildung: { abschluss: {}, ausbildungtyp: {}, ausbildungdauer: {} },
        subventionsbeitrag: {},
      },
      kantone: [],
      beitragReiterKey: 0,
      */
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.subventionsbeitrag) {
        return `${this.$t("global.subsidycontribution")} ${
          this.subventionsbeitrag.bezeichnung
        }`;
      } else {
        return this.$t("global.newsubsidycontribution");
      }
    },
    beitragstati: {
      get() {
        return this.$store.state.subventionbeitrag.beitragstati;
      },
    },
    subventionskantone: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
    beitragsintervalle: {
      get() {
        return this.$store.state.subventionbeitrag.beitragsintervalle;
      },
    },
  },

  created() {
    this.loading = true;

    if (this.subventionsbeitragid) {
      Api.get("bildung/subvention/beitrag/", {
        params: { id: this.subventionsbeitragid },
      })
        .then((response) => {
          this.initializeSubventionsbeitrag(response.data);
        })
        .finally(() => (this.loading = false));
    } else {
      this.loading = false;
      this.editable = true;
    }
  },

  mounted() {
    this.$store.dispatch(
      `subventionbeitrag/${LADE_AUSWAHLWERTE_SUBVENTION_BEITRAG}`
    );
    this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
  },

  methods: {
    abbrechen() {
      this.$router.push({ name: "subventionsbeitragliste" });
    },

    /*
    setKantone(val) {
      this.kantone = val;
    },
*/
    initializeSubventionsbeitrag(subventionsbeitrag) {
      if (subventionsbeitrag) {
        this.subventionsbeitrag = subventionsbeitrag;
        this.initChangeWatcher(this.subventionsbeitrag);
      } else {
        console.error("Subventionsbeitrag ist null");
      }

      this.editable = this.berechtigungen.b_bildung_bildungsportfolio.update;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.subventionsbeitrag);

      Api.put("bildung/subvention/beitrag/", json, {
        params: { id: this.subventionsbeitrag.id },
      })
        .then((response) => {
          this.initializeSubventionsbeitrag(response.data);
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.subsidycontributionsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });

      /*
      var json = Object.assign({}, this.subventionsbeitrag);

      console.log(json);

      if (json) {
        Api.post("subvention/beitrag/", json).then((response) => {
          this.count = response.count;
        });
      }


      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.subvention);

      if (!this.subventionid) {
        Api.post("bildung/subvention/", json)
          .then((response) => {
            this.initializeSubvention(response.data);
            this.$notify({
              type: "success",
              title: "Aktion erfolgreich",
              text: "Subvention erfolgreich gespeichert.",
            });

            this.$router.replace({
              name: "subvention",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("bildung/subvention/", json, {
          params: { id: this.subventionid },
        })
          .then((response) => {
            this.initializeSubvention(response.data);
            this.$notify({
              type: "success",
              title: "Aktion erfolgreich",
              text: "Subvention erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
      */
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "subvention-loeschen-modal");
    },
    subventionsbeitragLoeschen() {
      Api.delete("bildung/subvention/beitrag/", {
        params: { id: this.subventionsbeitrag.id },
      }).then(() => {
        this.$router.push({ name: "subventionsbeitragliste" });
      });
    },
  },
};
</script>

<style></style>
